<script setup lang="ts">
/**
 * основной шаблон для страниц состоит из 3 частей
 * 1. шапка с лого / тайтл и кнопкой назад
 * 2. тело
 * 3. подвал прибитый к низу страницы
 */
interface Props {
  header?: string;
  headerIcon?: string;
  headerLogo?: boolean;
  pageClass?: string;
  back?: boolean;
  isAuth?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  header: '',
  headerIcon: '',
  headerLogo: false,
  pageClass: '',
  back: true,
  isAuth: false,
});
const { back, header, headerIcon, pageClass } = toRefs(props);
</script>
<template>
  <div class="page" :class="pageClass">
    <AHeader :header="header" :header-logo="headerLogo" :header-icon="headerIcon" :back="back" :is-auth="isAuth">
      <slot name="header" />
    </AHeader>
    <article class="page-body">
      <div class="container">
        <slot name="body" />
      </div>
    </article>
    <footer class="page-action">
      <div class="container">
        <slot name="action" />
      </div>
    </footer>
  </div>
</template>
<style lang="scss">
.page {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
}
.page .a-header .logo-icon {
  height: 32px;
}
.page-body {
}
.page-action {
  padding: 20px 0;
  margin-top: auto;
}
</style>
